<template>
    <v-data-table
        :headers="headers"
        :items="loading ? [] : items"
        :items-per-page="itemsPerPage"
        :hide-default-footer="hideDefaultFooter"
        :sort-by="sortByHeaders"
        :sort-desc="sortByDesc"
        class="elevation-0 rounded-lg reports__table"
        :loading="loading"
        height="100%"
    >

        <template v-slot:item.course_name="{ item }" >
            <v-chip label color="deep-purple lighten-5" class="px-9 my-3">
                <v-row align-content="center" justify="start">
                    <v-icon color="deep-purple" class="mr-2" small>
                        mdi-folder
                    </v-icon>
                    <span class="body-2 deep-purple--text lighten-5 text-capitalize font-weight-medium">
                        {{ item?.course_name }}
                    </span>
                </v-row>
            </v-chip>
        </template>

        <template v-slot:item.fluency_level="{ item }" >
            <span v-if="!item?.fluency_level">
                -
            </span>
            <span v-else>
                {{ $t(`evaluate.evaluations.readingSpeed.${item.fluency_level}`) }}
            </span>
        </template>

        <template v-slot:item.word_per_minute="{ item }" >
            <span v-if="!item?.word_per_minute">
                -
            </span>
            <span v-else>
                {{ item?.word_per_minute }}
            </span>
        </template>

        <template v-slot:item.correct_words_per_minute="{ item }" >
            <span v-if="!item?.correct_words_per_minute">
                -
            </span>
            <span v-else>
                {{ item?.correct_words_per_minute }}
            </span>
        </template>
        
        <template v-slot:item.reading_category="{ item }" >
            <span v-if="!item?.reading_category">
                -
            </span>
            <span v-else>
                {{ $t(`evaluate.evaluations.readingQuality.${item.reading_category}`) }}
            </span>
        </template>

        <template v-slot:item.id="{ item }" v-if="withActions">
            <v-row class="flex-nowrap" align-content="center" justify="center">
                <!-- Evaluate -->
                <v-btn
                    v-if="canEdit"
                    icon
                    elevation="0"
                    class="deep-purple lighten-5 ma-1 pa-3"
                    small
                    @click="handleEdit(item)"
                >
                    <v-icon small class="pa-3" color="deep-purple">mdi-pencil</v-icon>
                </v-btn>
                <!-- Delete -->
                <v-btn
                    v-if="canDelete"
                    icon
                    elevation="0"
                    class="red lighten-5 ma-1 pa-3"
                    small
                    @click="deleteClass(item)"
                >
                    <v-icon small class="pa-3" color="red">mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>

export default {
    name: 'BasicTable',
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        items: {
            type: Array,
            default: () => [],
            required: false,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
            required: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: true,
            required: false,
        },
        sortByHeaders: {
            type: Array,
            default: () => [],
            required: false,
        },
        sortByDesc: {
            type: Boolean,
            default: false,
            required: false,
        },
        headers: {
            type: Array,
            default: () => [],
            required: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
            required: false,
        },
        canDelete: {
            type: Boolean,
            default: false,
            required: false,
        },
        withActions: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    methods: {
        handleEdit(course) {
            this.$emit('on-update', course)
        },
        deleteClass(course) {
            const vm = this
            this.$modal.confirm(
                this.$t('library.confirm_delete'),
                this.$t('diagnoses.actions.confirmation.delete'),
                async () => {
                    this.$emit('on-delete', course)
                }
            )
        },
    }
}
</script>

<style scope lang="scss">
@import '../../../assets/style/colors.scss';

.headers-table {
    background: $purple-primary !important;
}

.reports__table {
    border-radius: 9px;
    overflow: hidden;
}

.reports__table .v-data-table-header {
    background-color: $purple-primary;
}
.reports__table .v-data-table-header .sortable.active {
    background-color: #d7d2ef;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: none !important;
}
.evaluation-option {
    cursor: pointer;
}

.text-no-wrap {
    word-break: keep-all;
}
</style>
